<template>
  <v-app>
    <v-row class="d-flex justify-center">
      <v-container>
        <vue-html2pdf
          :show-layout="true"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          filename="Invoices"
          :pdf-quality="2"
          :pdf-margin="[30, 0, 30, 0]"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="landscape"
          pdf-content-width="1400px"
          @onProgress="onProgress"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            <!-- PDF Content Here -->
            <v-card tile width="1122">
              <v-card-text>
                <v-row>
                  <v-col cols="6" v-for="(w, i) in winners" :key="i">
                    <v-card outlined height="365" max-height="365">
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <h3>Nama Pengirim</h3>
                          </v-col>
                          <v-col>
                            <p>
                              <strong>:</strong>
                              Kedas Beauty Pusat
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <h3>Nama Penerima</h3>
                          </v-col>
                          <v-col>
                            <p>
                              <strong>:</strong>
                              {{ w.name }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <h3>No. HP</h3>
                          </v-col>
                          <v-col>
                            <p>
                              <strong>:</strong>
                              {{ w.phone }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <h3>Alamat</h3>
                          </v-col>
                          <v-col>
                            <p>
                              <strong>:</strong>
                              {{ w.address }}
                            </p>
                          </v-col>
                        </v-row>
                        <br />
                        <v-divider></v-divider>
                        <p class="align-self-end">
                          Dapatkan Informasi Event Berhadiah dan Info Produk
                          Kedas Beauty Hanya Di
                          <strong>kedasbeautymember.com</strong>
                        </p>
                      </v-card-text>
                    </v-card>
                    <div
                      v-if="(i + 1) % 4 === 0"
                      class="html2pdf__page-break"
                    ></div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </section>
        </vue-html2pdf>
      </v-container>
    </v-row>

    <v-dialog v-model="progress.show">
      <v-card>
        <v-card-text>
          <v-progress-linear :value="progress"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
import VueHtml2pdf from "vue-html2pdf";

export default {
  data() {
    return {
      progress: {
        value: 0,
        show: false,
      },
      winners: null,
    };
  },
  methods: {
    onProgress(progress) {
      this.progress.value = progress;
    },
    async getWinners(event_id) {
      await axios
        .get(
          `${process.env.VUE_APP_KB_API_URL}/api/participants/winners/${event_id}`
        )
        .then((response) => {
          this.winners = response.data;
        });
    },
  },
  components: {
    VueHtml2pdf,
  },
  watch: {
    "progress.value": (val) => {
      if (val === 100) {
        this.progress.show = false;
      }
    },
  },
  mounted() {
    let event_id = this.$route.query.event_id;
    this.getWinners(event_id).then(() => {
      this.$refs.html2Pdf.generatePdf();
    });
  },
};
</script>